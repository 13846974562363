// Supermove
import {gql} from '@supermove/graphql';
import {ThreadModel, UserModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import EmailSenderKind from '@shared/modules/Organization/enums/EmailSenderKind';

const isEnabledEmailReply = withFragment(
  (thread: ThreadModel, viewer: UserModel) => {
    const {isEnabledEmailReply: isEnabledEmailReplyFlag} = thread.project.organization.features;
    const {emailSenderKind} = thread.project.organization.settings;
    const userAccountGrantEmail = viewer.activeAccountGrant?.identifier;
    const organizationAccountGrantEmail =
      thread.project.organization.activeAccountGrant?.identifier;
    // To use email reply, the flag must be enabled, the organization must be sending either through the user or the organization,
    // and there must be a relevant active account grant
    const isEnabledEmailReply =
      isEnabledEmailReplyFlag &&
      emailSenderKind !== EmailSenderKind.SUPERMOVE &&
      (!!organizationAccountGrantEmail ||
        (emailSenderKind === EmailSenderKind.USER && !!userAccountGrantEmail));

    return isEnabledEmailReply;
  },
  gql`
    fragment Thread_isEnabledEmailReply_Thread on Thread {
      id
      project {
        id
        organization {
          id
          settings {
            id
            emailSenderKind
          }
          activeAccountGrant {
            id
            identifier
          }
          features {
            isEnabledEmailReply: isEnabled(feature: "EMAIL_REPLY")
          }
        }
      }
    }

    fragment Thread_isEnabledEmailReply_Viewer on User {
      id
      activeAccountGrant {
        id
        identifier
      }
    }
  `,
);

const Thread = {
  isEnabledEmailReply,
};

export default Thread;
